export class ServiceFilterModel {
  customerId: number;
  netId: number;
  searchText: string;
  orderBy: string = 'id';
  orderByDirection: string = 'DESC';
  top: number = 10;
  skip: number;
  serviceStationId: number = null;
  receivedFromSiteId: number = null;
  serviceStatusIds: string = null;
  receivedDateFrom: Date = null;
  receivedDateTo: Date = null;
  washedDateFrom: Date = null;
  washedDateTo: Date = null;
  testedDateFrom: Date = null;
  testedDateTo: Date = null;
  repairedDateFrom: Date = null;
  repairedDateTo: Date = null;
  antifouledDateFrom: Date = null;
  antifouledDateTo: Date = null;
  spaghettiDateFrom: Date = null;
  spaghettiDateTo: Date = null;
  hasPlannedDeliveryDate: boolean = null;
  plannedDeliveryDateFrom: Date = null;
  plannedDeliveryDateTo: Date = null;
  deliveredDateFrom: Date = null;
  deliveredDateTo: Date = null;
  declinedServiceApprovalFrom: Date = null;
  declinedServiceApprovalTo: Date = null;
  storageId: number = null;
  showInvoicedAny: boolean = null;
  showInvoicedWashing: boolean = null;
  showInvoicedTesting: boolean = null;
  showInvoicedAntifouling: boolean = null;
  showInvoicedRepair: boolean = null;
  showInvoicedDelivery: boolean = null;
  showNetsInStorage: boolean = null;
  showSpecialProducts: boolean = null;
  measuredDateFrom: Date = null;
  measuredDateTo: Date = null;
  approvedExtendedPeriodFrom: Date = null;
  approvedExtendedPeriodTo: Date = null;
}
