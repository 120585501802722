import { Expose } from 'class-transformer';

@Expose()
export class SiteStatus {
  IsAquacomCustomer: boolean;

  IsCustomerActive: boolean;
  CustomerFound: boolean;

  IsSiteActive: boolean;
  SiteFound: boolean;
}
