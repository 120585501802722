var registry: { [key: string]: EntityMetadata; } = {};

export function getBaseUrl(target: Function) {

  const metadata = getMetadata(target);

  return metadata.route;
}

function getMetadata(target: Function): EntityMetadata {

  let metadata = registry[target.name];

  if (!metadata) {
    metadata = new EntityMetadata();

    registry[target.name] = metadata;
  }

  return metadata;
}

export enum QueryOption {
    None,
    Filter,
    OrderBy,
    Top,
    Skip
}

export enum StringMask {
    None,
    Email,
    Phone,
    CreditCard,
    Url,
    Custom
}

export function convertAll<T>(c: { new (): T; }, src: any): T[] {

  if (!Array.isArray(src)) {
    return null;
  }

  let arr = <any[]>src;
  let out = [];

  for (let i = 0; i < arr.length; i++) {
    out.push(convert(c, arr[i]));
  }

  return out;
}

export function convert<T>(c: { new (): T; }, src: any): T {

  if (typeof src !== "object") {
    return null;
  }

// ReSharper disable once InconsistentNaming
  const obj = new c();

  for (let i in src) {
    if (obj.hasOwnProperty(i)) {
      obj[i] = src[i];
    }
  }

  return obj;
}

export function deserialize<T>(c: { new (): T; }, json: string): T {

  try {
    return convert(c, JSON.parse(json));
  }
  catch (err) {
    return null;
  }

}

export function serialize<T>(entity: T): string {

  const func = Object.getPrototypeOf(entity).constructor;

  if (!func) {
    throw new Error("Could not find type of entity.");
  }

  const meta = getMetadata(func);
  const prop = Object.getOwnPropertyNames(entity);

  const copy = {};

  for (let i = 0; i < prop.length; i++) {

    const name = prop[i], value = entity[name];

    if (meta.isReadOnly(name)) {
      continue;
    }

    if (meta.isOutOfRange(name, value)) {
      continue;
    }

    if (meta.isNonMatch(name, value)) {
      continue;
    }

    copy[name] = entity[name];
  }

  return JSON.stringify(copy);
}

export function options(...options: QueryOption[]) {
  return (target: any): void => {
    const md = getMetadata(target);

    md.options = options || md.options;
  };
}

export function action(route: string, payload: Function) {
  return (target: any): void => {
    getMetadata(target).actions.push(new Action(route, payload));
  };
}

export function readOnly() {
  return (target: any, propertyKey: string | symbol): void => {
    getMetadata(target).readOnly.push(propertyKey.toString());
  }
}

export function mask(mask: string | StringMask) {
  return (target: any, propertyKey: string | symbol): void => {

    var re: RegExp;

    if (mask === StringMask.Email) {
      re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    } else if (mask === StringMask.None) {
      re = /.*/;
    } else {
      re = new RegExp(mask.toString());
    }

    getMetadata(target).masks[propertyKey.toString()] = re;
  }
}

export function range(lowerBound: number, upperBound: number) {
  return (target: any, propertyKey: string | symbol) => {
    getMetadata(target).ranges[propertyKey.toString()] = new Range(lowerBound, upperBound);
  }
}

class EntityMetadata {
  route: string;
  options: QueryOption[];
  actions: Action[] = [];
  readOnly: string[] = [];
  masks: { [key: string]: RegExp; } = {};
  ranges: { [key: string]: Range; } = {};

  isReadOnly(propertyName: string): boolean {

    for (let i = 0; i < this.readOnly.length; i++) {
      if (this.readOnly[i] === propertyName) {
        return true;
      }
    }

    return false;
  }

  isOutOfRange(propertyName: string, value: any): boolean {

    const range = this.ranges[propertyName];

    if (range) {
      return typeof value !== "number" || range.lowerBound > value || value > range.upperBound;
    }

    return false;
  }

  isNonMatch(propertyName: string, value: any) {

    const regex = this.masks[propertyName];

    if (regex) {
      return typeof value !== "string" || regex.exec(value) === null;
    }

    return false;
  }
}

class Action {
  constructor(public route: string, public payloadType: Function) {
  }
}

class Range {
  constructor(public lowerBound: number, public upperBound: number) {
  }
}
